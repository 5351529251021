.FormControl {
  min-height: 94px!important;

  p {
    opacity: 0;
    transition: .2s ease-in-out;
    &.Mui-error {
      opacity: 1;
    }
  }
}

.MuiInputLabel-formControl {
  &:after {
    content: '';
    width: 110%;
    height: 120%;
    background-color: #fff;
    pointer-events: none;
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.MuiFormHelperText-root {
  @media screen and (max-width: 560px) {
    font-size: 0.6rem!important;
    margin-top: 2px!important;
    line-height: 1.4!important;
  }
}