.Welcome {
  margin-top: 20vh;
  text-align: center;

  h1 {
    font-weight: 800;
    font-size: 64px;
    margin-bottom: 50px;
  }

  a {

  }
}