.Task1 {
  margin-top: 5vh;
  margin-bottom: 5vh;

  form {
    max-width: 560px;
    margin: auto;

    .FormControl:last-of-type {
      min-height: 0!important;
    }

    button {
      width: 320px;
      margin: 19px auto 0;
      display: block;

      @media screen and (max-width: 560px) {
        max-width: 260px;
      }
    }
  }
}